<template>
  <v-app app>
    <v-navigation-drawer v-model="rightDrawer" width="245" class=" -z-10">
      <div class="w-full h-[84px] flex items-center border-b px-6">
        <a href="https://ayandehcement.com/">
          <img src="~@/assets/images/logo.webp" alt="logo" class="w-[170px] h-[48px]" />
        </a>
      </div>
      <div class="flex flex-col px-6 pt-8 gap-y-3">
        <RouterLink
          v-for="(item, index) in menuItems"
          v-show="item.display"
          :key="index"
          v-ripple
          :to="item.to"
          class="h-[40px] flex items-center gap-x-2 !p-2 rounded-lg"
          :class="activeLink === item.to ? 'bg-[#e6f1fd]' : ''"
          @click="activeLink = item.to"
        >
          <BaseIcon :icon="item.icon" :fill="$route.path === item.to" class="!text-blue-dark-500" />
          <span class="!text-sm !font-bold !text-primary-900">{{ item.title }}</span>
        </RouterLink>
      </div>
      <div class="fixed flex flex-col gap-3 bottom-6 right-6 text-blue-dark-200">
        <div class="flex items-center gap-2">
          <BaseIcon icon="encrypted" />
          <span class="text-sm">
            {{ authStore.userInfo.last_name }} -
            {{ `کد اشتراک ${authStore.userInfo.id}` }}
          </span>
        </div>
        <div class="flex items-center gap-2">
          <BaseIcon icon="copyright" />
          <span class="text-sm"> سیمان آینده - نسخه 1.5.3 </span>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main class="block">
      <div
        class="w-full h-[84px] sticky top-0 bg-white z-10 hidden md:flex justify-between items-center px-6 !shadow-none border-b"
      >
        <div class="flex items-center gap-x-3">
          <div class="flex cursor-pointer" @click.stop="rightDrawer = !rightDrawer">
            <BaseIcon icon="menu" class="text-primary-900" />
          </div>
          <span class="text-sm font-bold text-primary-900">
            {{ pageTitle }}
          </span>
        </div>
        <div class="flex gap-5">
          <preInvoice />
          <!-- <BaseNotifications /> -->
          <v-menu v-model="usermenu" transition="slide-x-transition" scroll-strategy="close">
            <template v-slot:activator="{ props }">
              <div
                v-if="authStore.userInfo.avatar_url"
                v-bind="props" :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }"
                class="border-[1.3px] border-primary-500 w-10 h-10 rounded-lg cursor-pointer"
              >
              </div>
              <div
                v-else
                v-bind="props"
                class="w-10 h-10 border-[1.3px] border-primary-500 rounded-lg grid place-items-center cursor-pointer"
              >
                <BaseIcon :icon="authStore.userInfo.account_type === 'personal' ?'account-circle' : 'domain'" class="text-primary-500" />
              </div>
            </template>
            <div class="bg-white rounded-lg w-60 user-menu">
              <div class="flex items-center justify-between px-4 py-2 pb-3 border-b">
                <div class="flex items-center gap-3">
                  <div
                    v-if="authStore.userInfo.avatar_url"
                    :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }"
                    class="w-[40px] h-[40px] grid place-content-center border-2 rounded-lg"
                  >
                  </div>
                  <div v-else class="w-[40px] h-[40px] grid place-content-center border-2 border-primary-500 rounded-lg">
                    <BaseIcon :icon="authStore.userInfo.account_type === 'personal' ?'account-circle' : 'domain'" class="text-primary-500" />
                  </div>
                  <div class="flex flex-col gap-y-1">
                    <RouterLink to="/user-account" class="flex items-center text-blue-500">
                      <span class="text-sm font-bold"> {{ authStore.userInfo.company_name ? authStore.userInfo.company_name :authStore.userInfo.first_name + ' ' + authStore.userInfo.last_name }} </span>
                      <BaseIcon icon="arrow-left" />
                    </RouterLink>
                    <span class="text-xs text-blue-dark-200">
                      {{ `کاربر - کد اشتراک ${authStore.userInfo.id || ''}` }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="px-4 py-2 border-b">
                <div class="cursor-pointer" @click="userAccountModal.openDialog()">
                  <BaseIcon icon="switch-account" class="ml-2 align-middle text-blue-dark-500" />
                  <span class="text-sm font-bold text-blue-dark-400">حساب های کاربری</span>
                </div>
                <div class="mt-5 mb-2">
                  <router-link to="/wallet">
                    <BaseIcon icon="history" class="ml-2 align-middle text-blue-dark-500" />
                    <span class="text-sm font-bold text-blue-dark-400">تاریخچه کیف پول</span>
                  </router-link>
                </div>
              </div>
              <div class="px-4 py-2 cursor-pointer" @click="logOut">
                <div class="mb-2">
                  <BaseIcon icon="power" class="ml-2 align-middle text-blue-dark-500" />
                  <span class="text-sm font-bold text-blue-dark-400">خروج از سیستم</span>
                </div>
              </div>
            </div>
          </v-menu>
        </div>
      </div>
      <div
        v-if="!!($route.path === '/dashboard')"
        class="w-full h-[64px] sticky top-0 bg-white z-10 md:hidden flex justify-between items-center px-5 !shadow-none border-b"
      >
        <a href="https://ayandehcement.com/">
          <div class="w-[150px] h-10 relative">
            <TransitionGroup name="slide-up">
              <img v-if="timer > 0" src="~@/assets/images/logo.webp" alt="logo" class="absolute w-auto h-10" />
              <img v-if="timer === 0" src="~@/assets/images/logo-mobile.webp" alt="logo" class="absolute w-auto h-10" />
            </TransitionGroup>
          </div>
        </a>
        <div class="flex items-center gap-4">
          <preInvoice />
          <!-- <BaseNotifications /> -->
        </div>
      </div>
      <div class="h-auto max-w-full md:h-auto">
        <div class="mb-20 md:mb-0">
          <slot />
        </div>
        <MobileNavigation :auth-store="authStore" @log-out="logOut" />
      </div>
      <UserAccounts v-if="!smAndDown" ref="userAccountModal" class="mt-5" />
      <div v-if="!isOnline" class="fixed top-0 z-[9999999] w-full text-center text-white bg-red-600 py-2 text-sm h-[84px] flex items-center justify-center">
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline ml-4 animate-bounce">
          <g id="System / Wifi_Off">
            <path id="Vector" d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z" stroke="#FFC3BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </svg>
        از دسترسی خود به اینترنت اطمینان حاصل کنید
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/authStore";
import { isEmpty } from "lodash";
import { onMounted, watch, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import MobileNavigation from "./components/MobileNavigation.vue";
import UserAccounts from '@/components/user/UsersAccounts.vue'
import BaseNotifications from "@/components/base/BaseNotifications.vue";
import preInvoice from "@/components/store/preInvoice.vue";
const { smAndDown } = useDisplay()
const authStore: any = useAuthStore();
const route = useRoute();
const router = useRouter();
const display = useDisplay();
const usermenu = ref(false)
const rightDrawer = ref(display.mdAndUp);
const pageTitle = ref("");
const menuItems = [
  {
    icon: "dashboard",
    title: "داشبورد",
    to: "/dashboard",
    display: true
  },
  {
    icon: "store",
    title: "فروشگاه",
    to: "/store",
    display: true
  },
  {
    icon: "receipt",
    title: "سفارش‌ها ",
    to: "/cards",
    display: true
  },
  {
    icon: "local-shipping",
    title: "بارها",
    to: "/orders",
    display: true
  },
  {
    icon: "receipt-long",
    title: "صورتحساب",
    to: "/invoices",
    display: true
  },
  {
    icon: "",
    title: "کیف پول",
    to: "/wallet",
    display: false
  },
  {
    icon: "",
    title: "پروفایل",
    to: "/user-account",
    display: false
  },
];
const moreItemsMenu = ref(false);
const timerId: any = ref<number | undefined>(0);
const timer = ref(1);
const userAccountModal = ref()
const isOnline = ref(true)
window.addEventListener('offline', function(e) {
  isOnline.value = false
});
const activeLink = ref(route.path)
window.addEventListener('online', function(e) {
  isOnline.value = true
});
// watch route path and assign to active link
watch(
  () => route.path,
  () => {
    if (activeLink.value !== route.path) activeLink.value = route.path
  }
);

// computed of get isOnline
const getIsOnline = computed(() => {
  return isOnline.value
})
const startTimer = () => {
  if (timerId.value) {
    clearInterval(timerId.value);
  }
  timer.value = 3;
  timerId.value = setInterval(() => {
    timer.value--;
    if (timer.value <= 0) {
      clearInterval(timerId.value);
      timerId.value = undefined;
    }
  }, 1000);
};

onMounted(() => {
  if (!authStore.isLoggedIn) {
    router.push(`/auth?redirect=${route.fullPath}`);
  }
  pageTitle.value = menuItems.find((item) => item.to === route.path)?.title as string;
  startTimer();
});
const openDialogAfterLogin = () => {
  const action = route.query?.action
  // router.replace({'query': undefined});
  if (!authStore.userInfo?.companies_count) {
    return
  }
  if (action && action == 'account') {
    if (!smAndDown.value) {
      usermenu.value = true
    } else moreItemsMenu.value = true
  }
}
openDialogAfterLogin()
watch(route, (val) => {
  pageTitle.value = menuItems.find((item) => item.to === route.path)?.title as string;
});

watch(route, () => {
  if (!authStore.isLoggedIn) {
    router.push({ name: "auth" });
  }
  if (route.path === "/dashboard") {
    startTimer();
  }
});

watch(
  () => authStore.userInfo,
  (val) => {
    if (!authStore.isLoggedIn) {
      router.push({ name: "auth" });
    }
  },
  {
    deep: true,
  }
);

const logOut = () => {
  moreItemsMenu.value = false;
  localStorage.setItem('company', '')
  authStore.logout();
  window.open('/auth', '_self')
};
</script>

<style scoped lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.user-menu {
  box-shadow: 2px 16px 40px 0px rgba(176, 212, 250, 0.40);
}
</style>
