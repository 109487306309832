<template>
  <BaseBottomSheet v-model="dialog">
    <div>
      <div class="flex items-center justify-between pb-3 border-b">
        <div class="flex items-center gap-3">
          <RouterLink to="/user-account">
            <div
              v-if="authStore.userInfo.avatar_url"
              :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }"
              class="w-[40px] h-[40px] grid place-content-center border-2 rounded-lg"
            >
            </div>
            <div v-else class="w-[40px] h-[40px] grid place-content-center border-2 border-primary-500 rounded-lg">
              <BaseIcon :icon="authStore.userInfo.account_type === 'personal' ? 'account-circle' : 'domain'" class="text-primary-500" />
            </div>
          </RouterLink>
          <div class="flex flex-col gap-y-1">
            <RouterLink to="/user-account" class="flex items-center text-blue-500">
              <span class="text-sm font-bold"> {{ fullName }} </span>
              <BaseIcon icon="arrow-left" />
            </RouterLink>
            <span class="text-xs text-blue-dark-200">
              {{ `کاربر - کد اشتراک ${authStore.userInfo.id || ''}` }}
            </span>
          </div>
        </div>
        <UserAccounts />
      </div>
      <v-list class="flex flex-col p-0 mt-4 gap-y-3">
        <v-list-item
          link
          to="/invoices"
          exact
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          @click="dialog = false"
        >
          <template v-slot:prepend>
            <BaseIcon icon="receipt-long" :fill="$route.path === '/invoices'" />
          </template>
          <v-list-item-title class="!text-sm !font-bold mr-2">
            صورتحساب
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          to="/wallet"
          @click="dialog = false"
        >
          <template v-slot:prepend>
            <BaseIcon icon="wallet" :fill="$route.path === '/wallet'" />
          </template>
          <v-list-item-title class="!text-sm !font-bold mr-2">
            تاریخچه کیف پول
            <span class="text-red-500"> (جدید) </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="smAndDown && !isPWA"
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          @click="openAppInstaller"
        >
          <template v-slot:prepend>
            <BaseIcon icon="install-mobile" :fill="$route.path === '/wallet'" />
          </template>
          <v-list-item-title class="!text-sm !font-bold mr-2">
            وب اپلیکیشن (PWA)
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          @click="emit('logOut')"
        >
          <template v-slot:prepend>
            <BaseIcon icon="power" />
          </template>
          <v-list-item-title class="!text-sm !font-bold mr-2">
            خروج از سیستم
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="border-t-[1px] border-blue-dark-50 mt-4"></div>
      <div class="mt-2 text-center text-blue-dark-200">
        <BaseIcon icon="copyright" class="align-middle" />
        <span class="mr-2 text-xs"> سیمان آینده - نسخه 1.5.3</span>
      </div>
    </div>
  </BaseBottomSheet>
  <BaseBottomSheet v-model="pwaDialog" class="h-full">
    <div class="flex flex-col justify-between">
      <div class="h-[68vh]">
        <div class="mt-14">
          <img src="~@/assets/images/logo-mobile.webp" alt="logo" class="w-[94px] h-auto my-3 mx-auto " />
          <div class="mt-4 text-center">
            <BaseIcon icon="install-mobile" class="align-middle text-blue-dark-400" fill />
            <span class="mr-2 text-sm font-bold text-blue-dark-500">راهنمای نصب وب اپلیکیشن</span>
          </div>
          <div class="px-3 py-4 mt-6 border border-dashed rounded-lg border-blue-dark-50">
            <div class="flex items-center">
              <div class="relative">
                <div class="pt-1 text-sm text-center rounded bg-blue-dark-50 w-7 h-7">
                  1
                </div>
                <div class=" absolute border border-left border-blue-dark-200 h-[25px] right-3">
                </div>
              </div>
              <div class="mr-3 text-xs">
                <div v-if="platform.ios">
                  <span>در نوار مرورگر دکمه</span>
                  <BaseIcon icon="ios-share" class="mx-2 mb-1 text-xl align-middle text-blue-dark-400" />
                  <span>را بزنید</span>
                </div>
                <div v-else>
                  <span>در منوی بالا گزینه</span>
                  <BaseIcon icon="more-vert" class="text-xl align-middle text-blue-dark-400" />
                  <span>(بیشتر) را بزنید</span>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-6">
              <div class="relative">
                <div class="pt-1 text-sm text-center rounded bg-blue-dark-50 w-7 h-7">
                  2
                </div>
                <div class=" absolute border border-left border-blue-dark-200 h-[25px] right-3">
                </div>
              </div>
              <div class="mr-3 text-xs">
                <div v-if="platform.ios">
                  گزینه<BaseIcon icon="add" class="w-5 h-5 pb-1 mx-2 mb-1 text-base border-2 rounded text-blue-dark-400 border-blue-dark-400" />Add to home screen را انتخاب کنید.
                </div>
                <div v-else>
                  <span>
                    سپس گزینه <span class="font-bold">افزودن به صفحه اصلی</span> یا <span class="font-bold">نصب</span> را انتخاب کنید.
                  </span>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-6">
              <div class="relative">
                <div class="pt-1 text-sm text-center rounded bg-blue-dark-50 w-7 h-7">
                  3
                </div>
                <div class=" absolute border border-left border-blue-dark-200 h-[25px] right-3">
                </div>
              </div>
              <div class="mr-3 text-xs">
                <span>در مرحله بعد <span class="font-bold">افزودن</span> را انتخاب کنید.</span>
              </div>
            </div>
            <div class="flex items-center mt-6">
              <div class="relative">
                <div class="pt-1 text-sm text-center rounded bg-blue-dark-50 w-7 h-7">
                  4
                </div>
              </div>
              <div class="mr-3 text-xs">
                <span>از این پس آیکون</span>
                <img src="~@/assets/images/logo-mobile.webp" alt="logo" width="20" class="inline-block mx-2" />
                <span>در صفحه اصلی گوشی شما دردسترس است.</span>
              </div>
            </div>
            <div class="text-center">
              <v-btn color="secondary" class="w-auto px-4 mt-6" @click="pwaDialog = false">
                متوجه شدم
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseBottomSheet>
</template>

<script lang="ts" setup>
import BaseBottomSheet from "@/components/base/BaseBottomSheet.vue";
import { isPWA } from "@/core/helpers/pwa-helper";
import { useAuthStore } from "@/stores/authStore";
import { computed, onMounted, ref } from "vue";
import { useDisplay } from "vuetify";
import UserAccounts from '@/components/user/UsersAccounts.vue'
const authStore = useAuthStore();
const { smAndDown, platform } = useDisplay();
const deferredPrompt: any = ref(null)
const pwaDialog = ref()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  deferredPrompt.value = event;
});
const emit = defineEmits(["update:modelValue", "logOut"]);

const dialog = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});

const fullName = computed(() => {
  return authStore.userInfo.company_name ? authStore.userInfo.company_name :authStore.userInfo.first_name + ' ' + authStore.userInfo.last_name;
});
const openAppInstaller = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();
    const { outcome } = await deferredPrompt.value.userChoice;
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }
    deferredPrompt.value = null;
  } else {
    pwaDialog.value = true
  }
}
</script>

<style lang="scss" scoped></style>
